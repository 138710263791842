.loader {
    border: 7px solid #f3f3f3; 
    border-top: 7px solid #000;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spinloader 2s linear infinite;
}
.loader img{
    height : 80px;
    width : 80px;
    animation: spinlogo 2s linear infinite;
}
@keyframes spinloader {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes spinlogo {
    0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
}