.useremail{
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0;
}

/* Media query for screens smaller than 600px */
@media screen and (max-width: 600px) {
.hideOnMobile{
    display: none !important;
}
}

/* Media query for screens between 600px and 900px */
/* @media screen and (min-width: 600px) and (max-width: 900px) {
    .hideProfile{
        display: none;
    }
} */

/* Media query for screens larger than 1200px */
@media screen and (min-width: 1200px) {
 
}