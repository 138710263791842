/*Credit Card.css */
.creditCard { 
    min-width: 430px; 
    min-height: 230px;
    background: linear-gradient(45deg, #1f4760, #3a6e8c); 
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  }
  
  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(10px); 
    z-index: -1;
    opacity: 0.5; 
  }
  
  .card-content {
    position: relative;
    padding: 16px; 
  }

  .truncate {
    white-space: nowrap;  
    overflow: hidden;        
    text-overflow: ellipsis; 
  }
  