
.amplify-button--primary {
    background-color: #1f4760;
}

.preAuditLogo-on-registerPage{
    max-height: 50px;
    margin-bottom: 20px;
   }
.amplify-tabs-item[data-state=active] {
 color: #fff;
 border-color: #0fa781;
}
.amplify-tabs-item[data-state=active]:hover {
    color: #fff;
   }

.amplify-tabs-item:hover {
    color: #00152a;
   }

[data-amplify-authenticator] [data-amplify-router] {
 background-color: #ffffff;
 border-color: #728fa1;
}
.amplify-tabs-item[data-spacing=equal]{

}
.amplify-button--link {
    color: #00152a;
}
.amplify-button--link:hover {
    color: #00152a;
    background-color: #fff;
}

.amplify-input, .amplify-input:focus {
border-color: #1f4760;
border: 0.5px solid;
}
.fieldContainer{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
select, .amplify-field-group__outer-end .amplify-field-group__control, .amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child), .amplify-field-group__outer-start--quiet .amplify-field-group__control, .amplify-field-group__outer-start .amplify-field-group__control:not(:first-child), .amplify-field-group :not(:first-child) .amplify-input {
border-color: #1f4760;
border: 0.5px solid;
}
.amplify-input{
    border-color: #1f4760;
}

.amplify-checkbox__icon {
  background-color: #1f4760;
  border-color: #1f4760;
}
.input-select-field{
    border-color: #1f4760;
}
.amplify-select{
    border-color: #1f4760 !important;
}
.loginParennt{
display: flex;
justify-content: space-between;
}
.loginSplitA{
    display: flex;
    justify-content: center;
    width: 40%;
    flex-direction: column;
    align-items: center;
}
.loginSplitB{
    display: flex;
    justify-content: center;
    width: 60%;
}


@media (max-width: 768px) {

  .loginSplitA{
    display: none;
  }
  .loginSplitB{
    background-color: #1f4760;
    display: flex;
    justify-content: center;
    width: 100% !important;
    align-items: center;
}
}