.mainContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.contentContainer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: start;
  flex-wrap: wrap;
}
.fileUploadSection{
    width: 50%;
}
.fileInfoSection{
    display: flex;
    width: 40%;
}
.fileUploaderContainer{
    border: 3px dotted #cfcfcf;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
}
.fileUploader{
    width: 100%;
    text-align: start;
}
:where(.css-dev-only-do-not-override-1okl62o).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: #ffffff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    border-radius: 6px !important;
}

/* Media query for screens smaller than 600px */
@media screen and (max-width: 600px) {
    .kbUploadCards{
        display: flex;
        flex-direction: column;
    }
    
    .fileUploaderContainer{
        background-color: #f2f2f2;
        padding: 30px;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .btn{
        width: 100% !important;
        margin-top: 30px;
    }
    .fileUploadSection{
        width: 100%;
        margin-bottom: 30px;
    }
    .fileInfoSection{
        display: flex;
        width: 100%;
    }
}

/* Media query for screens between 600px and 900px */
@media screen and (min-width: 600px) and (max-width: 900px) {
    .kbUploadCards{
        display: flex;
        flex-direction: column;
    }
    .fileUploaderContainer{
        background-color: #f2f2f2;
        padding: 30px;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .btn{
        width: 100% !important;
        margin-top: 30px;
    }
    .fileUploadSection{
        width: 100%;
        margin-bottom: 30px;
    }
    .fileInfoSection{
        display: flex;
        width: 100%;
    }
}

/* Media query for screens larger than 1200px */
@media screen and (min-width: 1200px) {

}