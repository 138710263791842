.mainContent{
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
}
.firstSection{
    text-align: start;
}
.secondSection{
    display: flex;
    align-items: center;
    justify-content: center;
}
.menuOnHover:hover{
    background-color: #f3f3f3 !important;
}
.previewSection{
    height: 100%;
    width: 26rem;
}
input[type="file"] {
    display: none;
}
.inputfileButton{
    display: inline-block;
    align-items: center;
    /* background-color: #73767a; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


/* Media query for screens smaller than 600px */
@media screen and (max-width: 600px) {
    .mainContent{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .button{
      width: 100% !important;    
    }
    .previewSection{
        width: 100%;
        margin-top: 30px;
    }
    .firstSection{
        width: 100%;
        text-align: start;
        margin-bottom: 50px;
    }
    .secondSection{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: start;
    }
}

/* Media query for screens between 600px and 900px */
@media screen and (min-width: 600px) and (max-width: 900px) {
    .mainContent{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .previewSection{
        width: 100%;
    }
    .firstSection{
        width: 100%;
        text-align: start;
        margin-bottom: 50px;
    }
    .secondSection{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: start;
    }
}

/* Media query for screens larger than 1200px */
@media screen and (min-width: 1200px) {
 
}