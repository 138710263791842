
  .predefinedContent{
    display: flex !important;
    justify-content: space-between;
  }
  .questionList{
  width: 50%;
  margin: 10px;
  }
  .questionAndAnswerInput{
    width: 50%;
    margin: 10px;
  }

  @media screen and (max-width: 600px){
   
  .predefinedContent{
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;
  }
  .questionList{
    width: 100%;
    }
    .questionAndAnswerInput{
      width: 100%;
    }
  
  }
  
  
  @media screen and (min-width: 600px) and (max-width: 900px) {
    .predefinedContent{
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between;
    }
    .questionList{
      width: 100%;
      }
      .questionAndAnswerInput{
        width: 100%;
      }
  }